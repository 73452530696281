import React from 'react';
// import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useCookies } from 'react-cookie';

const ProfileHeader = ({ todayReservation, todayComing, bg, canteenName, game = false, design = ''}) => {
    const { setModal, setShowModal } = useAuth();

    // Provider
    const [cookies] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_user`]);
    const userFirstName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['firstName'] ?? ' '
    const userLastName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['lastName'] ?? ' '
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const today = new Date()
    // let color = ('points-header' === bg) ? '' : '-white'

    todayComing = null;
    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    const hideModal = () => {
        setShowModal(false)
    }

    const showConfirmation = () => {
        if( todayReservation && todayComing ){
            setModal({
                title: 
                    <div className='comfirm-presence-title comfirm-presence-ok-title'>Passage validé, bon appétit !</div>,
                content: 
                    <div className='confirm-presence'>
                        <div className='presence-complete-day'>{ completeDays[today.getDay()] } {String(today.getDate()).padStart(2, '0')} { completeMonths[today.getMonth()] }</div>
                        <div className='presence-message presence-message-ok'>Votre compte Jabu a été crédité de<div className='points-claimed'>10pts</div></div>
                        <button onClick={hideModal} className='presence-continue presence-ok-continue'>Continuer</button>

                        <Link to={'/my-points'}>
                            <button onClick={hideModal} className='presence-ok-see-points see-points'>Consulter mon solde Jabu</button>
                        </Link>
                    </div>
            })
        }else{
            setModal({
                title: 
                    <div className='comfirm-presence-title comfirm-presence-ko-title'>Repas non réservé</div>,
                content: 
                    <div className='confirm-presence'>
                        <div className='presence-complete-day'>{ completeDays[today.getDay()] } {String(today.getDate()).padStart(2, '0')} { completeMonths[today.getMonth()] }</div>
                        <div className='presence-message presence-message-ko'>Pensez a réserver votre repas 72h à l’avance pour nous aider à limiter le gaspillage et cumuler des points Jabu !</div>
                        <button onClick={hideModal} className='presence-continue presence-ko-continue'>Continuer</button>

                        <Link to={'/my-points'}>
                            <button onClick={hideModal} className='presence-ko-see-points see-points'>Consulter mon solde Jabu</button>
                        </Link>
                    </div>
            })
        }
        setShowModal(true)
    }

    const modal = async () => {
        setModal({
            title: <div style={{backgroundColor: '#F3C121', color: '#000'}} className='d-flex justify-content-center align-items-center flex-column py-2 mb-3'>Règles du jeu</div>,
            content: <div className='d-flex justify-content-center align-items-center flex-column px-4'>
                <p className='fw-bold'>Votes</p>
                <p>Vous obtenez 10 points par vote</p>
                <p className='fw-bold'>Bonus: Avis sur les repas</p>
                <p>Vous obtenez 2 points par avis sur les repas</p>
            </div>
        })
        setShowModal(true)
    }

    if( 'new' == design ){
        return ( cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`] &&
            <>
                <div className={`${bg} calendar_reservation_new d-flex flex-column w-100`}>
                    <div className='d-flex p-1'>
                        <Link to='/my-profile' className='col-2'>
                            <div className='header-avatar-name d-flex'>
                                <span className='avatar-name'>{userFirstName.slice(0,1)}{userLastName.slice(0,1)}</span>
                            </div>
                        </Link>
    
                        <div className='my-canteen col-8 d-flex align-items-center justify-content-center'>
                            { 'undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`] ?
                                <Link className='d-flex' to='/my-family'>
                                    <div className='my-canteen-text'>{cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['name']}</div>
                                    <div>
                                        <i className='fa-solid fa-chevron-down'></i>
                                    </div>
                                </Link>
                                :
                                <Link className='d-flex' to='/my-canteens'>
                                    <div>
                                        <i className='fa-solid fa-chevron-down'></i>
                                        {/* <img src={`/images/down-arrow${color}-icon.png`} alt='Choix de la cantine'></img> */}
                                    </div>
                                    <div className='my-canteen-text'>{canteenName}</div>
                                </Link>
                            }
                        </div>
    
                        <div className='header-qr-code col-2 d-flex justify-content-end align-items-center'>
                            { false == todayComing &&
                                <Link className='d-flex' to='/qr-code-presence'>
                                    <i className='fa-regular fa-qrcode'></i>
                                </Link>
                            }
    
                            { true == todayComing &&
                                <i role='presentation' className='fa-regular fa-qrcode' onClick={showConfirmation}></i>
                            }
    
                            {true == game &&
                                <div className='d-flex'>
                                    <img role='presentation' onClick={() => modal()} className='points-img' src='/images/world-cup-icon.png' alt='Mes points'></img>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }else{
        return ( cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`] &&
            <>
                <div className={`${bg} d-flex flex-column w-100`}>
                    <div className='d-flex p-1'>
                        <Link to='/my-profile' className='col-2'>
                            <div className='header-avatar d-flex'>
                                <div className='avatar-name-block'>
                                    <span className='avatar-name'>{userFirstName.slice(0,1)}{userLastName.slice(0,1)}</span>
                                    {/* <img className='avatar' src='/images/user.png' alt='avatar'></img> */}
                                </div>
    
                                <div className='header-points'>
                                    <span className='my-points-balance'>{userPoints}</span> pts
                                </div>
                            </div>
    
                            <div className="points-gain d-none">+10</div>
                        </Link>
    
                        <div className='my-canteen col-8 d-flex align-items-center justify-content-center'>
                            { 'undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`] ?
                                <Link className='d-flex' to='/my-family'>
                                    <div className='my-canteen-text'>{cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['name']}</div>
                                    <div>
                                        <i className='fa-solid fa-chevron-down'></i>
                                    </div>
                                </Link>
                                :
                                <Link className='d-flex' to='/my-canteens'>
                                    <div className='my-canteen-text'>{canteenName}</div>
                                    <div>
                                        <i className='fa-solid fa-chevron-down'></i>
                                        {/* <img src={`/images/down-arrow${color}-icon.png`} alt='Choix de la cantine'></img> */}
                                    </div>
                                </Link>
                            }
                        </div>
    
                        <div className='header-qr-code col-2 d-flex justify-content-end align-items-center'>
                            { false == todayComing &&
                                <Link className='d-flex' to='/qr-code-presence'>
                                    <i className='fa-regular fa-qrcode'></i>
                                </Link>
                            }
    
                            { true == todayComing &&
                                <i role='presentation' className='fa-regular fa-qrcode' onClick={showConfirmation}></i>
                            }
    
                            {true == game &&
                                <div className='d-flex'>
                                    <img role='presentation' onClick={() => modal()} className='points-img' src='/images/world-cup-icon.png' alt='Mes points'></img>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ProfileHeader
