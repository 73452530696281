import React from 'react';
import { Link } from 'react-router-dom';

const TutorialOld = ({step, canteenName}) => {
    let previousButton = '', nextButton = '', title = '', content = ''
    let step1 = 'step-inactive', step2 = 'step-inactive', step3 = 'step-inactive', step4 = 'step-inactive'
    if( step > 1 ){
        previousButton = 
            <i className='tutorial-nav-button fa-solid fa-circle-chevron-left'></i>
    }

    if( step < 3 && canteenName != 'SKF' ){
        nextButton = 
            <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
    }

    if( step < 4 && canteenName == 'SKF' ){
        nextButton = 
            <i className='tutorial-nav-button fa-solid fa-circle-chevron-right'></i>
    }

    if( 1 == step ){
        step1 = 'step-active'
        if( canteenName != 'SKF' ){
            title = `
                Accès rapide aux plats et à l’affluence
            `
            content = `
                Sélectionner votre plat, choisissez votre créneau, scanner le QR lors de votre passage à la cantine et gagner des points Jabu !
            `
        }else{
            title = `
                Bienvenue sur Jabu !
            `
            content = `
            Votre application de réservation qui vous aide à mieux manger et moins jeter !
            `
        }
    }

    if( 2 == step ){
        step2 = 'step-active'
        if( canteenName != 'SKF' ){
            title = `
                Laissez-vous prendre au jeu
            `
            content = `
                Les points Jabu vous permettent de profiter d’offres exclusives auprès de notre partenaires et d’accéder aux paniers invendus
            `
        }else{
            title = `
                Réservez votre repas
            `
            content = `
            Faites votre choix jusqu'à 72h à l'avance. Aider à anticiper, c'est déjà lutter contre le gaspillage.
            `
        }
    }

    const previousStep = step - 1
    const nextStep = step + 1
    let navigation = ''
    let skip = <span></span>
    let nextLink = `/tutorial/${nextStep}`

    if( 3 == step ){
        step3 = 'step-active'   
        if( canteenName != 'SKF' ){
            title = `
                Des paniers invendus à prix réduits
            `
            content = `
                Bénéficiez de paniers invendus et aidez nous à réduire le gaspillage du début à la fin !
            `    
            nextButton = 
                <span className='tutorial-go'>Go !</span>

            nextLink = '/'
        }else{
            title = `
                Validez votre présence
            `
            content = `
                À votre arrivée, scannez le QR Code à la caisse/entrée et montrez votre écran de réservation. Facile et rapide pour une restauration plus responsable !
            `    
            nextLink = `/tutorial/${nextStep}`
        }
    }

    if( 4 == step ){
        if( canteenName != 'SKF' ){
            title = `
                Restez informé
            `
            content = `
                Des nouveautés dans votre cantine ? Des paniers invendus disponibles ?
                Recevez nos notifications push pour ne rien manquer
            `

            navigation = 
                <div className='tutorial-navigation-ready'>
                    <Link to='/'>
                        <div className='tutorial-ready'>C’est parti !</div>
                    </Link>
                    
                    <Link to='/'>
                        <div className='tutorial-maybe-later'>Peut-être plus tard</div>
                    </Link>
                </div>
        }else{
            step4 = 'step-active'   
            title = `
                Partagez et gagnez
            `
            content = `
                Votre avis compte ! Partagez-le et participez à nos actions anti-gaspi pour accumuler des points et profiter de récompenses. 
            `
            nextButton = 
                <span className='tutorial-go'>Go !</span>
        }
    }

    if( 5 == step && canteenName == 'SKF' ){
        title = `
            Découvrez les paniers Anti-Gaspi
        `
        content = `
            Grâce à notre module anti-gaspi, profitez de repas à petit prix tout en faisant un geste supplémentaire pour la planète.
        `

        navigation = 
            <div className='tutorial-navigation-ready'>
                <Link to='/'>
                    <div className='tutorial-ready'>C’est parti !</div>
                </Link>
                
                <Link to='/'>
                    <div className='tutorial-maybe-later'>Peut-être plus tard</div>
                </Link>
            </div>
    }

    if( step < 4 && canteenName != 'SKF' ){
        skip = 
            <span>Ignorer</span>

        navigation = 
            <div className='tutorial-navigation'>
                <div className='tutorial-navigation-button'>
                    <Link to={`/tutorial/${previousStep}`}>{previousButton}</Link>
                </div>
                
                <div className='d-flex flex-grow-1 justify-content-center'>
                    <i className={`fa-solid fa-minus ${step1}`}></i>
                    <i className={`fa-solid fa-minus ${step2}`}></i>
                    <i className={`fa-solid fa-minus ${step3}`}></i>
                </div>

                <div className='tutorial-navigation-button'>
                    <Link to={nextLink}>{nextButton}</Link>
                </div>
            </div>
    }

    if( step < 5 && canteenName == 'SKF' ){
        skip = 
            <span>Ignorer</span>

        navigation = 
            <div className='tutorial-navigation'>
                <div className='tutorial-navigation-button'>
                    <Link to={`/tutorial/${previousStep}`}>{previousButton}</Link>
                </div>
                
                <div className='d-flex flex-grow-1 justify-content-center'>
                    <i className={`fa-solid fa-minus ${step1}`}></i>
                    <i className={`fa-solid fa-minus ${step2}`}></i>
                    <i className={`fa-solid fa-minus ${step3}`}></i>
                    <i className={`fa-solid fa-minus ${step4}`}></i>
                </div>

                <div className='tutorial-navigation-button'>
                    <Link to={nextLink}>{nextButton}</Link>
                </div>
            </div>
    }

    if( 'SKF' == canteenName ){
        step += '-SKF' 
    }

    return (
        <>
            <div className='d-flex justify-content-center'>
                <img src='/images/logo-black.png' alt='Jabu'></img>
            </div>

            <div className='tutorial-image'>
                <img className='w-100' src={`/images/tutorial-${step}.png`} alt='Jabu'></img>
            </div>

            <div className='tutorial-skip'>
                <Link to='/'>
                    {skip}
                </Link>
            </div>

            <div className='tutorial-title'>
                {title}
            </div>

            <div className='tutorial-content'>
                {content}
            </div>

            {navigation}
        </>
    )
}

export default TutorialOld
