import React from 'react';
import Tutorial from '../components/TutorialOld';

const StayTunedPage = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    return (
        <div className="tutorial-page">
            <Tutorial step={4}></Tutorial>
        </div>
    );
};

export default StayTunedPage;
