import React, { useState, useEffect } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';
import {DatePicker, LocalizationProvider, PickersDay} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller, useForm } from 'react-hook-form';
import {Badge, FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

const UserReview = () => {
    const navigate = useNavigate();
    const params = useParams();
    const today = dayjs(new Date().toJSON().slice(0, 10));
    const minDate = today.add(-30, 'days');
    const currentDate = params?.date ? dayjs(params.date) : today;
    const [scenario, setScenario] = useState('review');
    const [meals, setMeals] = useState({});
    const [review, setReview] = useState({});
    const [dateReview, setDateReview] = useState({});
    const [cookie, setCookie] = useCookies();
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']
    const [canteenDishOrigin, setCanteenDishOrigin] = useState(null);

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [display, setDisplay] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const { handleSubmit, reset, register, control} = useForm({
        defaultValues: {
            meal: null,
        },
    });

    const getReview = async (date) => {
        setIsLoading(true);

        try {
            const response = await axiosPrivate.get('/guests/canteen/menu', {
                params: {
                    date: date.format('YYYY-MM-DD'),
                    canteenId: canteenId,
                    slotType: 1,
                },
                withCredentials: true
            });
            setMeals(response.data.meals.meals)
        } catch (err) {
            setScenario('not-review')
        }

        const result = await axiosPrivate.get('/guests/meal/review', {
            params: {
                date: currentDate.format('YYYY-MM-DD'),
            },
            withCredentials: true,
        })

        let mealReview = result.data;
        let canteenDish, visual, quantity, taste, comment = null;
        if (mealReview.mealReview !== null) {
            canteenDish = mealReview.mealReview.canteenDish.id.toString();
            visual = mealReview.mealReview.isGoodVisual.toString();
            quantity = mealReview.mealReview.isGoodQuantity.toString();
            taste = mealReview.mealReview.isGoodTaste.toString();
            comment = mealReview.mealReview.comment;
        } else if (mealReview.reservationDish !== null) {
            canteenDish = mealReview.reservationDish.canteenDishId.toString();
            setCanteenDishOrigin(canteenDish);
        }

        reset({
            meal: canteenDish,
            visual: visual,
            quantity: quantity,
            taste: taste,
            comment: comment,
        })

        setReview(mealReview.mealReview)
        setDateReview(mealReview.dates)

        setDisplay(true);

        setIsLoading(false);
    };

    // Call Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        if (data.meal === null || data.visual === null || data.quantity === null || data.taste === null) {
            setFlash({ message: 'Veuillez remplir tous les champs' });
            setShowFlash(true);
            setIsLoading(false);
            return;
        }

        // Call API
        try {
            await axiosPrivate.post('/guests/meal/review', {
                canteenDishId: data.meal,
                canteenDishOriginId: canteenDishOrigin,
                dateMeal: currentDate.format('YYYY-MM-DD'),
                isGoodVisual: (data.visual === 'true'),
                isGoodQuantity: (data.quantity === 'true'),
                isGoodTaste: (data.taste === 'true'),
                comment: data.comment,
            }, {withCredentials: true});

            if (null !== auth.childId) {
                let child = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`];
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {id: child.id, name: child.name, point: child.point + 2 }, { path: '/' })
            } else {
                setAuth({ ... auth, jabuPoint: auth.jabuPoint + 2});
                setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ... auth, jabuPoint: auth.jabuPoint + 2 }, { path: '/' });
            }

            setFlash({ message: 'Tu viens de gagner 2 points', type: 'success' });
            navigate(`/review/${currentDate.add(1, 'day').format('YYYY-MM-DD')}`);
        } catch (err) {
            console.log(err)
            setFlash({ message: 'Veuilliez rééssayer' });
        }

        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    const handleDateChange = (date) => {
        navigate(`/review/${date.format('YYYY-MM-DD')}`);
    };

    const shouldDisableDate = (date) => {
        return undefined === dateReview[date.format('YYYY-MM-DD')] || dateReview[date.format('YYYY-MM-DD')].closed
    };

    const dateBefore = () => {
        navigate(`/review/${currentDate.add(-1, 'day').format('YYYY-MM-DD')}`);
    };

    const dateAfter= () => {
        navigate(`/review/${currentDate.add(1, 'day').format('YYYY-MM-DD')}`);
    };


    useEffect(() => {
        setDisplay(false)
        setScenario('review')
        if (currentDate > today || currentDate < minDate) {
            setScenario('not-review')
        }
        getReview(currentDate);

    }, [params.date]);
    
    return ( display &&
        <div className='home-page d-flex my-points min-vh-100'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='meal-header'>
                    <ProfileHeader game={true} bg='points-header' canteenName={canteenName} page='my-points'></ProfileHeader>
                </div>

                <div className='d-flex flex-grow-1 flex-column align-items-center' style={{marginTop: '50px', marginBottom: '60px'}}>
                    <div className='d-flex m-2 flex-column flex-grow-1 align-items-center'>
                        <div className='user-title d-flex justify-content-center'>
                            Je donne mon avis
                        </div>
                        <div className='d-flex justify-content-center align-items-center'>
                            <i role='presentation' onClick={() => dateBefore()} className='me-2 fa-solid fa-chevron-left fs-3'></i>
                            <div className='calendar-datepicker'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <DatePicker
                                        onChange={handleDateChange}
                                        minDate={minDate}
                                        maxDate={today}
                                        defaultValue={currentDate}
                                        loading={false}
                                        shouldDisableDate={shouldDisableDate}
                                        slots={{
                                            day: ServerDay,
                                        }}
                                        slotProps={{
                                            day: {
                                                dateReview,
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                            <i role='presentation' onClick={() => dateAfter()} className='ms-2 fa-solid fa-chevron-right fs-3'></i>
                        </div>

                        {scenario === 'not-review' &&
                            <div
                                className='user-point-classement d-flex justify-content-center align-items-center flex-column w-100 my-3 p-3'>
                                Vous ne pouvez pas donner votre avis.
                            </div>
                        }

                        { scenario === 'review' &&
                            <>
                                <div className='d-flex justify-content-center align-items-center flex-column w-100'>
                                    <form className="d-flex flex-column flex-grow-1" onSubmit={handleSubmit(submit)}>
                                        <div className='user-point-classement d-flex justify-content-center align-items-center flex-column w-100 my-3 p-3'>
                                            <span className='user-subtitle mb-2'>Qu&apos;est ce que j&apos;ai mangé ce midi ?</span>
                                            <FormControl>
                                                <Controller
                                                    control={control}
                                                    name="meal"
                                                    render={({field}) => (
                                                        <RadioGroup {...field} row className="justify-content-center text-center">
                                                            { meals.map((meal) => (
                                                                <FormControlLabel key={meal.id} disabled={review !== null} value={meal.id} control={<Radio/>} label={meal.name} labelPlacement="top"/>
                                                            ))}
                                                        </RadioGroup>
                                                    )}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className='user-point-classement d-flex justify-content-center align-items-center w-100 my-3 p-3'>
                                            <div className='d-flex justify-content-center align-items-center w-25'>
                                                <label htmlFor="visual-down">
                                                    <input {...register('visual')} className="review-down" type="radio" name="visual" id="visual-down" value="false" disabled={review !== null}/>
                                                    <i className='fa-solid fa-thumbs-down fa-2x'></i>
                                                </label>
                                            </div>
                                            <span className='d-flex justify-content-center align-items-center w-50 text-center user-subtitle'>J&apos;ai apprécié le visuel de mon plat</span>
                                            <div className='d-flex justify-content-center align-items-center w-25'>
                                                <label htmlFor="visual-up">
                                                    <input {...register('visual')} className="review-up" type="radio" name="visual" id="visual-up" value="true" disabled={review !== null}/>
                                                    <i className='fa-solid fa-thumbs-up fa-2x'></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='user-point-classement d-flex justify-content-center align-items-center w-100 my-3 p-3'>
                                            <div className='d-flex justify-content-center align-items-center w-25'>
                                                <label htmlFor="quantity-down">
                                                    <input {...register('quantity')} className="review-down" type="radio" name="quantity" id="quantity-down" value="false" disabled={review !== null}/>
                                                    <i className='fa-solid fa-thumbs-down fa-2x'></i>
                                                </label>
                                            </div>
                                            <span className='d-flex justify-content-center align-items-center w-50 text-center user-subtitle'>La quantité de mon plat était suffisante</span>
                                            <div className='d-flex justify-content-center align-items-center w-25'>
                                                <label htmlFor="quantity-up">
                                                    <input {...register('quantity')} className="review-up" type="radio" name="quantity" id="quantity-up" value="true" disabled={review !== null}/>
                                                    <i className='fa-solid fa-thumbs-up fa-2x'></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className='user-point-classement d-flex justify-content-center align-items-center w-100 my-3 p-3'>
                                            <div className='d-flex justify-content-center align-items-center w-25'>
                                                <label htmlFor="taste-down">
                                                    <input {...register('taste')} className="review-down" type="radio" name="taste" id="taste-down" value="false" disabled={review !== null}/>
                                                    <i className='fa-solid fa-thumbs-down fa-2x'></i>
                                                </label>
                                            </div>
                                            <span className='d-flex justify-content-center align-items-center w-50 text-center user-subtitle'>J&apos;ai aimé le goût de mon plat</span>
                                            <div className='d-flex justify-content-center align-items-center w-25'>
                                                <label htmlFor="taste-up">
                                                    <input {...register('taste')} className="review-up" type="radio" name="taste" id="taste-up" value="true" disabled={review !== null}/>
                                                    <i className='fa-solid fa-thumbs-up fa-2x'></i>
                                                </label>
                                            </div>
                                        </div>
                                        <div className='user-point-classement d-flex justify-content-center align-items-center flex-column w-100 my-3 p-3'>
                                            <span className='user-subtitle mb-2'>Pourquoi je n&apos;ai pas aimé le goût de mon plat ?</span>
                                            <textarea {...register('comment')} disabled={review !== null}></textarea>
                                        </div>
                                        { review !== null &&
                                            <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
                                                Votre avis vous a rapporté 2 points
                                            </div>
                                        }
                                        { review === null &&
                                            <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
                                                <input className='user-submit-satisfaction' type='submit' value='Valider'></input>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <MenuFooter item='review' unsoldsRemain={0}></MenuFooter>
            </div>
        </div>
    )
}

export default UserReview;

function ServerDay(props) {
    const { dateReview = [], day, outsideCurrentMonth, ...other } = props;

    let badgeContent = undefined;
    let color = 'default';
    if (!props.outsideCurrentMonth && undefined !== dateReview[day.format('YYYY-MM-DD')] && true === dateReview[day.format('YYYY-MM-DD')].review) {
        badgeContent = ' ';
        color = 'success';
    }

    return (
        <Badge key={props.day.toString()} overlap="circular" badgeContent={badgeContent} variant='dot' color={color}>
            <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
        </Badge>
    );
}
