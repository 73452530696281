import React, { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import LoginTemplate from '../../components/Login/LoginTemplate';
import Login from '../../components/Login/Login';
import { useCookies } from 'react-cookie';

const LoginPage = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');
    const debug = process.env.REACT_APP_DEBUG;
    const [, removeCookie] = useCookies();

    // Global
    const navigate = useNavigate();
    const { auth } = useAuth();

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            navigate('/');
        }

        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, undefined, {path: '/google/auth'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_children`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`, undefined, {path: '/'});
        removeCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`, undefined, {path: '/'});
    }, [])

    return (
        <div className="login login-page">
            <LoginTemplate debug={debug} child={<Login />}/>
        </div>
    );
};

export default LoginPage;
