import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const UserAllergens = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#0B50A4');
    
    // Global
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [displayUserConstraint, setDisplayUserConstraint] = useState(false);
    const [allergens, setAllergens] = useState([]);

    // Form
    const { reset, register, handleSubmit } = useForm();
    // Provider
    const { auth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [cookie, ] = useCookies();
    const isChild= auth.childId !== null;

    // Call Submit Canteen Setting Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/guests/setting', {
                allergens: data.allergens.map((allergen) => parseInt(allergen)),
                ...(isChild  ? { childId: auth.childId  } : {}),
            }, { withCredentials: true });

            setFlash({ message: 'Modification effectuée', type: 'success' });
            if( 'true' == cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] ){
                window.location = '/my-dietetary'
            }else{
                window.location = '/my-profile'
            }
        } catch (err) {
            console.log(err);
            navigate('/500');

            setFlash({ message: 'Erreur Serveur' });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    // Get Canteen Setting
    const getDietaryConstraints = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get('/dietary-constraints', { withCredentials: true });
        setAllergens(response.data.allergens);

        const responseUser = await axiosPrivate.get('/guests/setting', {
            withCredentials: true,
            params: {
                ...(isChild  ? { childId: auth.childId  } : {}),
            },
        });
        let data = responseUser.data;
        reset({
            allergens: data?.allergens.map((allergen) => String(allergen)) ?? [],
        });

        // Display Form
        setDisplayUserConstraint(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        getDietaryConstraints();
    }, []);

    let submitText = 'Valider'
    let skipButton = ''
    if( 'true' == cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] ){
        submitText = 'Suivant'
        skipButton = 
            <Link to='/tutorial'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <span className='skip-constraint'>Renseigner plus tard</span>
                </div>
            </Link>
    }

    return (
        <>
            { displayUserConstraint &&
                <div className='profil-page d-flex min-vh-100 justify-content-center'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                { 'true' !== cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] &&
                                    <Link to='/my-profile'>
                                        <span style={{backgroundColor: '#F5F4ED', borderRadius: '20px', padding: '10px' }}>
                                            <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                        </span>
                                    </Link>
                                }
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Bonjour {auth?.firstName}</div>
                            <div><img className='logo-tiny' src='/images/logo-tiny.png' alt='Jabu'></img></div>
                        </div>

                        <div className='d-flex flex-column profile-block flex-grow-1'>
                            <div className='user-title d-flex flex-column justify-content-center'>
                                Allergies
                                { isChild &&
                                    <span className='my-2 user-subtitle d-flex justify-content-center'>de {cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`].name }</span>
                                }
                            </div>

                            {/* <div className='user-subtitle'>
                                Indiquez nous vos allergies alimentaires
                            </div> */}

                            <form style={{ marginBottom: '150px'}} className="d-flex align-items-center flex-column" onSubmit={ handleSubmit(submit) } >
                                <div className='user-constraints'>
                                    { allergens.map((allergen) => (
                                        allergen.display ? (
                                            <label className='d-flex flex-column justify-content-center align-items-center' key={ allergen.id }>
                                                <input type="checkbox" name="allergens" value={ allergen.id } {...register('allergens')} />
                                                <span className="user-constraint">
                                                    <img src={`/images/${allergen.icon}`} alt={allergen.name}></img>
                                                </span>
                                                <span className='d-flex'>{ allergen.name }</span>
                                            </label>
                                        ) : null
                                    ))}
                                </div>

                                <div className='user-settings-actions'>
                                    <div className='d-flex flex-column align-items-center justify-content-center' style={{marginBottom: '10px'}}>
                                        <input className='user-submit' type='submit' value={submitText}></input>
                                    </div>

                                    {skipButton}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserAllergens;
