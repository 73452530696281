import React, { useEffect, useState } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useAuth from '../../hooks/useAuth';
import UnsoldCard from '../../components/Meal/UnsoldCard';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Unsolds = () => {
    const { auth } = useAuth();
    const [displayUnsolds, setDisplayUnsolds] = useState(false);
    const completeDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']
    const completeMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre']
    const [cookie] = useCookies([`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]);
    const canteenId = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const canteenName = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']
    const axiosPrivate = useAxiosPrivate();
    const [unsolds, setUnsolds] = useState(true);
    const [unsoldCards, setUnsoldCards] = useState(true);

    let today = new Date()
    let currentDate = today.toJSON().slice(0, 10)
    let currentDay = new Date(currentDate)

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#018C4E');

    const getCanteenInfos = async () => {
        const result = await axiosPrivate.get(`/guests/canteen?canteenId=${canteenId}`, {
            withCredentials: true,
            params: {
                childId: auth.childId,
            },
        })
        return result.data.canteen
    }

    const getUnsolds = async (canteen) => {
        try {    
            const result = await axiosPrivate.get(`/guests/canteen/unsolds?canteenId=${canteenId}&requestedDate=${currentDate}`, { withCredentials: true })
            if( result.data.remains >= 0 && true == canteen.unsoldActivated ){
                // setDisplayUnsolds(false)
                setDisplayUnsolds(true)
            }
            setUnsolds(result.data)
            setUnsoldCards(result.data.meals.map((meal, key) => {
                price = meal.price
                if( auth.jabuPoint < 10 ){
                    price = meal.price * 1.1
                }
        
                return <UnsoldCard key={key} id={meal.id} name={meal.name} price={price} remain={meal.remain} tags={[]}></UnsoldCard>
            }))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCanteenInfos().then((canteen) => {
            getUnsolds(canteen)
        })
    }, [])

    let price = 0

    if( displayUnsolds ){
        return (
            <div className='home-page unsolds d-flex min-vh-100 justify-content-center'>
                <div className='meal-header'>
                    <ProfileHeader bg='calendar_reservation' canteenName={canteenName} page='unsolds'></ProfileHeader>
                </div>
    
                <div role='presentation' id='unsold-infos' style={{alignItems:'initial'}}>
                    <div className='meal-complete-day d-flex justify-content-center mt-0'>{ completeDays[currentDay.getDay()] } {String(currentDay.getDate()).padStart(2, '0')} { completeMonths[currentDay.getMonth()] }</div>

                    <div className='unsolds-text'>Faites des économies en utilisant vos points Jabu !</div>

                    <div className='d-flex flex-column flex-grow-1'>
                        {unsoldCards}
                    </div>

                    <div className='unsolds-recup'>
                        <i className='fa-regular fa-clock'></i> A récupérer : {unsolds.opening.start.slice(0, -3)} - {unsolds.opening.end.slice(0, -3)}
                    </div>

                    <div className='unsolds-text-small text-center'>
                        En réservant ce panier, vous acceptez les <Link to='/cgvu'>Conditions Générales d’Utilisation</Link> de Jabu
                    </div>

                    <div className='d-flex justify-content-center'>
                        <Link to="/unsolds-cart">
                            <button type="submit" className='unsold-submit border-0'>Valider</button>
                        </Link>
                    </div>
                </div>
    
                <MenuFooter item='unsold' unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        );
    }

    return (
        <div className='home-page d-flex unsolds min-vh-100 justify-content-center'>
            <div className='meal-header'>
                <ProfileHeader bg='calendar_reservation' canteenName={canteenName} page='unsolds'></ProfileHeader>
            </div>

            <div id='unsold-infos'>
                <p className='px-4 text-center'><img className='unsold-image' src='/images/unsolds.png' alt='Invendus'></img></p>
                {/*<p className='px-4 text-center'>En route pour l&apos;antigaspi ! 🌍🍴</p>
                <p className='px-4 text-center'>Un peu de patience ! Bientôt vous pourrez dénicher les paniers anti-gaspi de Jabu et sauver les invendus de fin de service à prix doux.</p>
                <p className='px-4 text-center'>Et le meilleur dans tout ça ? Vous pouvez gagner des points dès maintenant pour avoir accès à ces paniers dès leur lancement.</p>
                <p className='px-4 text-center'>Chaque action compte : votez pour vos menus, indiquez votre présence et gagnez des points. Plus vous participez, plus vous économisez... et plus vous sauvez !</p>*/}
                <p className='px-4 text-center'>La fonctionnalité de panier anti-gaspi permet aux utilisateurs d&apos;acheter à prix réduit les surplus alimentaires de la cantine, contribuant ainsi à réduire le gaspillage alimentaire.</p>
                <p className='px-4 text-center'>La fonctionnalité de paniers anti-gaspi est actuellement désactivée. Nous aimerions connaître votre opinion à ce sujet ! Aidez-nous à comprendre votre intérêt en répondant à quelques questions.</p>
                <a className='add-invit mb-3' href='https://forms.gle/AbwrL8rdmqgEYm5r6' target='_blank' rel="noreferrer">Répondre au questionnaire</a>
            </div>

            <MenuFooter item='unsold' unsoldsRemain={unsolds.remains}></MenuFooter>
        </div>
    );
};

export default Unsolds;
