import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const UserConstraints = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');
    
    // Global
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [displayUserConstraint, setDisplayUserConstraint] = useState(false);
    const [dietaryConstraints, setDietaryConstraints] = useState([]);
    // Form
    const { reset, register, handleSubmit } = useForm();
    // Provider
    const { auth, setAuth, setFlash, setShowFlash, setIsLoading } = useAuth();
    const [cookie, setCookie] = useCookies();
    const isChild= auth.childId !== null;

    // Call Submit Canteen Setting Form
    const submit = async (data) => {
        // Flash Message
        setShowFlash(false);
        setFlash({});

        // Loader
        setIsLoading(true);

        // Call API
        try {
            await axiosPrivate.put('/guests/setting', {
                dietary_constraints: data.dietaryConstraints.map((dietaryConstraint) => parseInt(dietaryConstraint)),
                ...(isChild  ? { childId: auth.childId  } : {}),
            }, { withCredentials: true });

            setFlash({ message: 'Modification effectuée', type: 'success' });

            if( 'true' == cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] ){
                let children = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_children`];
                if (children !== 'undefined' && children.children.length > 1) {
                    const filteredItems = cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_children`].children.filter(child => child.id !== auth.childId);
                    setAuth({ ...auth, childId: filteredItems[0].id });
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_user`, { ...auth, childId: filteredItems[0].id}, { path: '/' })
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_children`, {...children, 'children': filteredItems }, { path: '/' })
                    setCookie(`${process.env.REACT_APP_COOKIE_PREFIX}_child`, {'id': filteredItems[0].id, 'name': filteredItems[0].firstName, 'point': filteredItems.jabuPoint}, { path: '/' })
                    window.location = '/my-constraint'
                } else {
                    window.location = '/tutorial'
                }
            }else{
                window.location = '/my-profile'
            }
        } catch (err) {
            console.log(err);
            navigate('/500');

            setFlash({ message: 'Erreur Serveur' });
        }

        // Flash Message
        setShowFlash(true);

        // Loader
        setIsLoading(false);
    }

    // Get Canteen Setting
    const getDietaryConstraints = async() => {
        // Loader
        setIsLoading(true);

        // Call API
        const response = await axiosPrivate.get('/dietary-constraints', { withCredentials: true });
        setDietaryConstraints(response.data.dietaryConstraints);

        const responseUser = await axiosPrivate.get('/guests/setting', {
            withCredentials: true,
            params: {
                ...(isChild  ? { childId: auth.childId  } : {}),
            },
        });
        let data = responseUser.data;
        reset({
            dietaryConstraints: data?.dietaryConstraints.map((dietaryConstraint) => String(dietaryConstraint)) ?? [],
        });

        // Display Form
        setDisplayUserConstraint(true);

        // Loader
        setIsLoading(false);
    }

    useEffect(() => {
        getDietaryConstraints();
    }, []);

    let submitText = 'Valider'
    let skipButton = ''
    if( 'true' == cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] ){
        submitText = 'Suivant'
        skipButton = 
            <Link to='/tutorial'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <span className='skip-constraint'>Renseigner plus tard</span>
                </div>
            </Link>
    }

    return (
        <>
            { displayUserConstraint &&
                <div className='profil-page d-flex min-vh-100 justify-content-center'>
                    <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                        <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                            <div className='d-flex profile-header-back'>
                                { 'true' !== cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_newcomer`] &&
                                    <Link to='/my-profile'>
                                        <span style={{backgroundColor: '#F5F4ED', borderRadius: '20px', padding: '10px' }}>
                                            <i style={{fontSize: '16px', width: '18px', textAlign: 'center', fontWeight: 'bold' }} className='fa-solid fa-chevron-left'></i>
                                        </span>
                                    </Link>
                                }
                            </div>
                            <div className='d-flex justify-content-center flex-grow-1 profile-header-text'>Bonjour {auth?.firstName}</div>
                            <div><img className='logo-tiny' src='/images/logo-tiny.png' alt='Jabu'></img></div>
                        </div>

                        <div className='d-flex flex-column profile-block flex-grow-1'>
                            <div className='user-title d-flex flex-column justify-content-center'>
                                Régimes alimentaires
                                { isChild &&
                                    <span className='my-2 user-subtitle d-flex justify-content-center'>de {cookie[`${process.env.REACT_APP_COOKIE_PREFIX}_child`].name }</span>
                                }
                            </div>

                            {/* <div className='user-subtitle'>
                                Indiquez nous vos régimes alimentaires
                            </div> */}

                            <form className="d-flex align-items-center flex-column" onSubmit={ handleSubmit(submit) } >
                                <div className='user-constraints'>
                                    { dietaryConstraints.map((dietaryConstraint) => (
                                        dietaryConstraint.display ? (
                                            <label key={ dietaryConstraint.id }>
                                                <input type="checkbox" name="dietaryConstraints" value={ dietaryConstraint.id } {...register('dietaryConstraints')} />
                                                <span className="user-constraint"><img src={`/images/${dietaryConstraint.icon}`} alt={dietaryConstraint.name}></img>{ dietaryConstraint.name }</span>
                                            </label>
                                        ) : null
                                    ))}
                                </div>

                                <div className='user-settings-actions'>
                                    <div className='d-flex flex-column align-items-center justify-content-center' style={{marginBottom: '10px'}}>
                                        <input className='user-submit' type='submit' value={submitText}></input>
                                    </div>

                                    {skipButton}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default UserConstraints;
