import React, { useState, useEffect } from 'react';
import ProfileHeader from '../../components/Profile/ProfileHeader';
import MenuFooter from '../../components/MenuFooter';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useCookies } from 'react-cookie';

const UserPoints = () => {
    const [unsolds, setUnsolds] = useState(true);
    const [cookies] = useCookies();
    const canteenId = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['id']
    const canteenName = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_canteen`]['name']

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    const { auth, setModal, setShowModal, setIsLoading } = useAuth();
    const [display, setDisplay] = useState(false);
    const [user, setUser] = useState();
    const [ranking, setRanking] = useState();
    const axiosPrivate = useAxiosPrivate();
    const today = new Date()
    const currentDate = today.toJSON().slice(0, 10)

    let userPoints;
    if ('undefined' !== cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]){
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_child`]['point'] ?? 0
    } else {
        userPoints = cookies[`${process.env.REACT_APP_COOKIE_PREFIX}_user`]['jabuPoint'] ?? 0
    }

    const getUser = async () => {
        setIsLoading(true);
        const result = await axiosPrivate.get('/guests', {
            params: {
                childId: auth.childId,
            },
            withCredentials: true
        })
        let data = result.data;
        data.unsolds = 0
        setUser(data);
        await getRanking();
        setDisplay(true);
        setIsLoading(false);
    };

    const getRanking = async () => {
        const result = await axiosPrivate.get('/guests/ranking', { withCredentials: true })
        let data = result.data;
        setRanking(data);
        console.log(data)
    };

    const openTrash = () => {
        setModal({
            title: <div style={{backgroundColor: '#F3C121', color: '#000'}} className='d-flex justify-content-center align-items-center py-2 mb-3'><img className='save-logo' src="/images/no-trash-icon.png" alt="Economies"></img>Réduisez le gaspillage avec Jabu</div>,
            content: <div className='d-flex justify-content-center align-items-center flex-column px-4'>
                <p>Félicitations ! Grâce à vos votes, vous avez réussi à éviter le gaspillage de {savingsPhrase} de nourriture. Chaque bouchée compte pour faire la différence. Continuez à manger intelligemment et à sauver de la nourriture !</p>
            </div>
        })
        setShowModal(true)
    }

    const openCO2 = () => {
        setModal({
            title: <div style={{backgroundColor: '#F3C121', color: '#000'}} className='d-flex justify-content-center align-items-center py-2 mb-3'><img className='save-logo' src="/images/co2-icon.png" alt="Economies"></img>Réduisez votre CO2 avec Jabu</div>,
            content: <div className='d-flex justify-content-center align-items-center flex-column px-4'>
                <p>Impressionnant ! Vous avez contribué à l&apos;économie de {co2Phrase} de CO2. Vos choix alimentaires aident à réduire l&apos;empreinte carbone. Continuez sur cette voie et aidons notre planète à respirer plus facilement !</p>
            </div>
        })
        setShowModal(true)
    }

    const openMealSaves = () => {
        //setModal({content: <div><span className='save-title'><img className='save-logo' src="/images/meal-icon.png" alt="Economies"></img>Plats sauvés avec Jabu</span><p className='save-content'>Bravo ! Vous avez sauvé {user.reservations} plats grâce à vos achats de paniers antigaspi. Chaque plat sauvé signifie moins de gaspillage et plus de ressources pour notre planète. Continuez à acheter malin et à sauver plus de plats !</p></div>})
        setModal({
            title: <div style={{backgroundColor: '#F3C121', color: '#000'}} className='d-flex justify-content-center align-items-center py-2 mb-3'><img className='save-logo' src="/images/meal-icon.png" alt="Economies"></img>Plats sauvés avec Jabu</div>,
            content: <div className='d-flex justify-content-center align-items-center flex-column px-4'>
                <p>🌱 Pour le moment, notre fonctionnalité d&apos;invendus n&apos;est pas active. Mais bientôt, grâce à vous, nous pourrons sauver de nombreux plats et mesurer ensemble notre impact positif ici sur Jabu. Restez connecté(e) ! 🍽️🌍</p>
            </div>
        })
        setShowModal(true)
    }

    const getUnsolds = async () => {
        try {    
            const result = await axiosPrivate.get(`/guests/canteen/unsolds?canteenId=${canteenId}&requestedDate=${currentDate}`, { withCredentials: true })
            setUnsolds(result.data)
        } catch (error) {
            setUnsolds({remains:null})
        }
    }

    useEffect(() => {
        getUser();
        getUnsolds()
    }, []);

    let savingsPhrase, co2Phrase = ''
    if( display ){
        let savings = user.reservations*120

        if( savings < 1000 ){
            savingsPhrase = savings + ' g'
        }else{
            savingsPhrase = new Intl.NumberFormat('fr-FR').format(savings/1000) + ' kg'
        }

        let co2 = user.reservations*184

        if( co2 < 1000 ){
            co2Phrase = co2 + ' g'
        }else{
            co2Phrase = new Intl.NumberFormat('fr-FR').format(co2/1000) + ' kg'
        }
    }
    
    return ( display &&
        <div className='home-page d-flex my-points min-vh-100'>
            <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                <div className='meal-header'>
                    <ProfileHeader game={true} bg='points-header' canteenName={canteenName} page='my-points'></ProfileHeader>
                </div>

                <div className='d-flex flex-grow-1 flex-column align-items-center' style={{marginTop: '50px', marginBottom: '60px'}}>
                    <div className='d-flex m-2 flex-column flex-grow-1 align-items-center'>
                        <div className='user-title d-flex justify-content-center'>
                            Mon impact
                        </div>
                        <div className='d-flex'>
                            <div role='presentation' className='meal-saves' onClick={() => openTrash()}>
                                <div><img src='/images/no-trash-icon.png' alt='Economies'></img></div>
                                <div className='meal-save-value'>{savingsPhrase}</div>
                            </div>

                            <div role='presentation' className='meal-saves' onClick={() => openCO2()}>
                                <div><img src='/images/co2-icon.png' alt='Economies'></img></div>
                                <div className='meal-save-value'>{co2Phrase}</div>
                            </div>

                            <div role='presentation' className='meal-saves' onClick={() => openMealSaves()}>
                                <div><img src='/images/meal-icon.png' alt='Economies'></img></div>
                                <div className='meal-save-value'>{user.unsolds}</div>
                            </div>
                        </div>

                        <div className='user-title d-flex justify-content-center'>
                            Compétition Jabu
                        </div>

                        <div className='user-point-classement d-flex justify-content-center align-items-center flex-column my-3 w-100'>
                            <div className='user-subtitle mb-2'>
                                Mon Classement
                            </div>
                            <div className='d-flex justify-content-center align-items-center flex-column'>
                                <img style={{width: '50px', height: '50px'}} src='/images/laurier.png' alt='Points'></img>
                                <span>{userPoints} points</span>
                            </div>

                            <div className='d-flex justify-content-center align-items-center w-100'>
                                <div className='d-flex justify-content-center align-items-center flex-column w-50'>
                                    <p className='my-0'><span
                                        className='fw-bold'>{ranking.rankLocal}</span>/{ranking.totalLocal}</p>
                                    <span className='user-subtitle my-0'>dans le collège</span>
                                </div>
                                <div className='d-flex justify-content-center align-items-center flex-column w-50'>
                                    <p className='my-0'><span
                                        className='fw-bold'>{ranking.rankDepartment}</span>/{ranking.totalDepartment}
                                    </p>
                                    <span className='user-subtitle my-0'>dans le département</span>
                                </div>
                            </div>
                        </div>
                        <div className='canteen-point-classement d-flex justify-content-center align-items-center flex-column my-3 w-100'>
                            <div className='user-subtitle mb-2'>
                                Classement des établissements
                            </div>
                            <table style={{fontSize: '12px'}} className="mt-2 table w-100 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Collège</th>
                                        <th scope="col">Points</th>
                                        <th scope="col">Rang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ranking.ranking.map((item, key) => (
                                        <tr key={key}>
                                            <td>{item.name}</td>
                                            <td>{item.points}</td>
                                            <td>{key + 1}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='canteen-point-classement d-flex justify-content-center align-items-center flex-column m-3 w-100'>
                            <div className='user-subtitle mb-2'>
                                Lot à gagner (à venir)
                            </div>
                            <img style={{width: '50px', height: '50px'}} src='/images/trophy.png' alt='Trophy'></img>
                            <div className='podium'>
                                <div className='third-place fw-bold'>3</div>
                                <div className='first-place fw-bold'>1</div>
                                <div className='second-place fw-bold'>2</div>
                            </div>
                        </div>


                        {/* <p className='px-4 text-center mt-3'><img className='unsold-image' src='/images/my-points.png'
                                                                  alt='Mon JABU'></img></p>
                        <p className='px-4 text-center'>Bientôt, transformez vos points Jabu en récompenses ! 🎁🌟</p>
                        <p className='px-4 text-center'>Vos efforts pour réduire le gaspillage alimentaire seront
                            bientôt récompensés.</p>
                        <p className='px-4 text-center'>Continuez à gagner des points en participant activement sur
                            Jabu.</p>
                        <p className='px-4 text-center'>Restez à l&apos;écoute pour découvrir les surprises que nous
                            vous préparons !</p>
                        */}
                    </div>
                </div>

                <MenuFooter item='jabu' unsoldsRemain={unsolds.remains}></MenuFooter>
            </div>
        </div>
    )
}

export default UserPoints;
