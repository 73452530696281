import React from 'react';
import Tutorial from '../components/Tutorial';
import { useParams } from 'react-router-dom';

const TutorialPage = () => {
    let params = useParams()
    let step = params?.step ? parseInt(params.step) : 1

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    return (
        <div className="tutorial-page">
            <Tutorial step={step}></Tutorial>
        </div>
    );
};

export default TutorialPage;
