import React from 'react';
import { Link } from 'react-router-dom';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';

const Register = () => {
    // Global
    const { setIsLoading } = useAuth();

    // Connect Social
    const loginSocial = async (type) => {
        if( true == checkCGU() ){
            // Loader
            setIsLoading(true);

            await axios.get(`/users/token/social/register/redirect?type=${type}`).then(response => {
                setIsLoading(false);
                window.location.href = response.data.redirect;
            }).catch(error => {
                console.error(error);
            });
        }
    };

    function checkCGU(manualRedirect = false){
        const cgvuChecked = document.querySelector('input[name="accept-cgvu"]').checked

        if( true == cgvuChecked ){
            if( true == manualRedirect ){
                window.location = '/register/password'
            }
        }else{
            document.querySelector('#error-accept-cgvu').style.display = 'block'
        }

        return cgvuChecked
    }

    return (
        <>
            <div className="login-form">
                <span className="login-subtext">S&apos;inscrire</span>

                {/* <button className="login-submit facebook" onClick={() => loginSocial('facebook') }>
                    <div className='social-icon'>
                        <img src="/images/facebook-icon.svg" alt="Facebook"></img>
                    </div>

                    <div className='social-text'>
                        <span>Continuer avec Facebook</span>
                    </div>
                </button> */}

                <button className="login-submit google" onClick={() => loginSocial('google')}>
                    <div className='social-text'>
                        <span>Continuer avec Google</span>
                    </div>

                    <div className='social-icon'>
                        <img src="/images/google-icon.svg" alt="Google"></img>
                    </div>
                </button>

                {/* <button className="login-submit apple" onClick={() => loginSocial('apple')}>
                    <div className='social-icon'>
                        <img src="/images/apple-icon.svg" alt="Apple"></img>
                    </div>

                    <div className='social-text'>
                        <span>Continuer avec Apple</span>
                    </div>
                </button> */}

                <hr data-content="ou"/>

                <div role='presentation' onClick={() => checkCGU(true)}>
                    <button className="login-submit">S&apos;inscrire avec un email</button>
                </div>

                <div className='login-subtitle'>
                    Déjà inscrit ? <Link to="/login" className="text-decoration-underline">Se connecter</Link>
                </div>

                <div id="error-accept-cgvu">Veuillez accepter les Conditions Generales de Ventes et d’Utilisation</div>

                <div className='login-subtitle' style={{paddingLeft: '5px', marginTop: '25px'}}>
                    <input type='checkbox' name='accept-cgvu'></input> Je declare avoir lu et accepte les <Link to="/cgvu" className="text-decoration-underline">Conditions Generales de Ventes et d’Utilisation</Link>
                </div>

                <div className='login-subtitle' style={{marginTop: '5px'}}>
                    Acceder a notre <Link to="/confidential" className="text-decoration-underline">Politique de Confidentialité</Link>
                </div>
            </div>
        </>
    );
};

export default Register;
