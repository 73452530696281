import React, { useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const ChoiceFamily = () => {
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#E5E5E5');

    // Global
    const { auth } = useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        // Redirect if choice already made
        if (auth?.childId !== null) {
            navigate('/');
        }
    }, [])

    return (
        <>
            <div className='profil-page d-flex min-vh-100 justify-content-center'>
                <div className='d-flex flex-column align-items-center flex-grow-1 flex-shrink-1'>
                    <div className='profile-header d-flex flex-grow-1 flex-shrink-1 min-vw-100 align-items-center'>
                        <div className='d-flex profile-header-back'>
                            <Link to='/register-model'>
                                <span style={{backgroundColor: '#DADADA', borderRadius: '20px', padding: '10px'}}>
                                    <i style={{
                                        color: '#0B50A4',
                                        fontSize: '16px',
                                        width: '18px',
                                        textAlign: 'center',
                                        fontWeight: 'bold'
                                    }} className='fa-solid fa-chevron-left'></i>
                                </span>
                            </Link>
                        </div>
                        <div className='d-flex justify-content-center flex-grow-1 profile-header-text me-0'>Mon profil
                        </div>
                    </div>

                    <div className='d-flex flex-column profile-block flex-grow-1'>
                        <div className='user-title d-flex justify-content-center'>
                            Ajouter un code Famille
                        </div>

                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className='my-4'>
                                <Link className='user-child-button d-flex justify-content-center align-items-center' to='/register-family'>Renseigner mon code famille</Link>
                            </div>
                            <div className='my-4'>
                                <Link className='user-child-button d-flex justify-content-center align-items-center' to='/register-children'>Je n&apos;ai pas de code famille</Link>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <p className='text-center'>Si un membre de votre famille à déjà créé un compte pour vos enfants. Demandez lui le code pour vous y rattacher</p>
                            <p className='text-center'>Le code famille permet d’identifier les membres de votre famille déjeunant à la cantine.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChoiceFamily;
